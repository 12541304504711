import axios from "axios";
import {$auth, httpUrl} from "../config/api.config";


class TaskService {

    async getAll(id){
        const {data} = await axios.get(httpUrl + `/task?id=${id}`);
        return data
    }

    async complete(userId, taskId){
        const {data} = await axios.patch(httpUrl + `/task`, {userId, taskId});
        return data
    }

    async create(dataFetch){
        const {data} = await $auth.post("/task", dataFetch);
        return data;
    }
    async delete(id){
        const {data} = await $auth.delete(`/task?id=${id}`);
        return data;
    }

}
//eslint-disable-next-line
export default new TaskService();
