import {useState} from "react";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

export function Providers({children}){

    const [client] = useState(
        new QueryClient({
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false
                }
            }
        })
    )

    return (
        <QueryClientProvider client={client}>
            {children}
        </QueryClientProvider>
    )

}