import axios from "axios";
import {httpUrl} from "../config/api.config";

class GameService {
    async start(id){
        const {data} = await axios.get(httpUrl+`/game/start?id=${id}`)
        return data
    }
    async play(requestData){
        const {data} = await axios.post(httpUrl+`/game/play`, requestData)
        return data
    }
}
//eslint-disable-next-line
export default new GameService()