
import {$host, httpUrl} from "../config/api.config";

class AdminService {
    async login(dataFetching){
        const {data} = await $host.post(httpUrl + "/admin/login",dataFetching);
        if(data){
            localStorage.setItem("accessToken", data?.accessToken);
        }
        return data;
    }
}

//eslint-disable-next-line
export default new AdminService()