import React from 'react';

import cl from "./confirm.module.scss"
import useSound from "use-sound";
import clickSound from "../../sounds/click.mp3";

const ConfirmPopup = ({func, visible, setVisible, title}) => {
    const [play] = useSound(clickSound)
    return (
        <div className={visible ? [cl.wrapper, cl.wrapperActive].join(" ") : cl.wrapper} onClick={()=>setVisible(false)}>
            <div className={cl.body} onClick={(e) => e.stopPropagation()}>
                <div className={cl.title}>{title}</div>
                <div className={cl.row}>
                    <button className={cl.confirm} onClick={()=>{
                        func();
                        play()
                        setVisible(false)
                    }}>Да</button>
                    <button className={cl.notconfirm} onClick={()=> {
                        play()
                        setVisible(false)
                    }}>Нет</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmPopup;