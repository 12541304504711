import React, {useEffect, useState} from 'react';
import cl from "./coin.module.scss"
import Lottie from "react-lottie";
import * as animationDataStars from "../../../animations/stars.json";
import * as animationDataCoins from "../../../animations/coins.json";
import useSound from "use-sound";
import winSound from "../../../sounds/win.mp3";
import loseSound from "../../../sounds/lose.mp3"
import {clearTimers} from "mobx-react-lite";

const Coin = ({amount, resultGame}) => {

    const [isFirstLoad, setIsFistLoad] = useState(true);
    const [isStopped, setIsStopped] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [animation, setAnimation] = useState(false)

    const win = useSound(winSound)
    const lose = useSound(loseSound)

    useEffect(() => {
        setTimeout(()=>{
            if(resultGame?.win){
                win[0]()
            }else{
                lose[0]()
            }
            if(!isFirstLoad){
                if(resultGame?.win){
                    setIsStopped(false);
                    setIsPlaying(true);

                }
                setAnimation(true);
                setTimeout(()=>{
                    setAnimation(false);
                }, resultGame?.win ? 2000 : 750)
            }else{
                setIsFistLoad(false)
            }
        }, 1300)
        return clearTimers
    }, [resultGame]);

    const defaultOptionsStars = {
        loop: false,
        autoplay: false,
        animationData: animationDataStars,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };
    const defaultOptionsCoins = {
        loop: false,
        autoplay: false,
        animationData: animationDataCoins,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <div className={cl.block}>
            <div className={cl.coins}>
                <Lottie
                    options={defaultOptionsCoins}
                    height={360}
                    width={360}
                    isStopped={isStopped}
                    isPaused={!isPlaying}
                    speed={1}
                    eventListeners={[
                        {
                            eventName: 'complete',
                            callback: () => {
                                setIsStopped(true);
                                setIsPlaying(false);
                            },
                        },
                    ]}
                />
            </div>
            <div className={[cl.coin, animation && cl.coinAnimation].join(" ")}>
                <svg width="202" height="209" viewBox="0 0 202 209" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_3971_693)">
                        <circle cx="101" cy="107.056" r="66" fill="#F66129"/>
                        <g filter="url(#filter1_i_3971_693)">
                            <circle cx="101" cy="107.056" r="66" fill="#F66129"/>
                        </g>
                        <mask id="mask0_3971_693" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="35" y="41"
                              width="132" height="133">
                            <circle cx="101" cy="107.054" r="66" fill="#F66129"/>
                        </mask>
                        <g mask="url(#mask0_3971_693)">
                            <rect x="37.2832" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="41.1807" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="45.0781" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="48.9766" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="52.874" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="56.7715" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="60.6689" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="64.5664" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="68.4639" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="72.3623" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="76.2598" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="80.1572" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="84.0547" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="87.9521" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="91.8496" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="95.748" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="99.6455" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="103.543" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="107.44" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="111.339" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="115.236" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="119.134" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="123.031" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="126.93" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="130.827" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="134.725" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="138.622" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="142.52" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="146.418" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="150.315" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="154.213" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="158.11" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="162.008" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                            <rect x="165.905" y="95.1445" width="0.779697" height="91.0275" fill="#FFD0B5"
                                  fillOpacity="0.2"/>
                        </g>
                        <mask id="mask1_3971_693" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="46" y="44"
                              width="117" height="116">
                            <path
                                d="M47.7153 119.871L46.1006 71.2291L60.4309 61.4401L77.2841 46.908L125.321 44.5869L149.339 61.4401C153.679 73.5839 162.357 98.4365 162.357 100.697C162.357 103.523 153.578 123.101 152.266 125.725C151.216 127.824 136.691 143.486 129.559 151.055L88.2841 159.128L62.651 143.89L47.7153 119.871Z"
                                fill="#D9D9D9"/>
                        </mask>
                        <g mask="url(#mask1_3971_693)">
                            <circle cx="100.999" cy="121.385" r="66" fill="#ED8E02"/>
                        </g>
                        <g className={animation ? cl.logo : cl.logoActive}>
                            <path
                                d="M134.413 78.2012H152.276L122.909 145.109H104.037L73.8626 88.5957H69.6241L99.3947 145.109H79.4131L46.7158 78.2012H92.4314L114.633 121.394L134.413 78.2012Z"
                                fill="#F66129" fillOpacity="0.6"/>
                            <path
                                d="M69.8636 86.9586L99.3225 142.881H80.2618L47.9591 76.7796H92.7817L114.871 119.753L115.251 120.492L115.597 119.737L135.269 76.7796H152.254L123.242 142.881H104.876L74.8154 86.5803L74.7014 86.3668H74.4593H70.2208H69.5519L69.8636 86.9586Z"
                                fill="url(#paint0_linear_3971_693)" stroke="url(#paint1_linear_3971_693)"
                                strokeWidth="0.807339"/>
                        </g>


                        <circle cx="101" cy="101" r="59.4404" stroke="#FACB32" strokeWidth="13.1193"/>
                        <g style={{mixBlendMode: "plus-lighter"}}>
                            <path
                                d="M35.0682 104.028C35.0229 103.024 35 102.015 35 101C35 64.5492 64.5492 35 101 35C137.451 35 167 64.5492 167 101C167 102.015 166.977 103.024 166.932 104.028C166.977 105.031 167 106.04 167 107.055C167 143.506 137.451 173.055 101 173.055C64.5492 173.055 35 143.506 35 107.055C35 106.04 35.0229 105.031 35.0682 104.028Z"
                                fill="url(#paint2_linear_3971_693)" fillOpacity="0.2"/>
                        </g>
                    </g>
                    <defs>
                        <filter id="filter0_d_3971_693" x="0" y="0" width="202" height="208.056"
                                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                           result="hardAlpha"/>
                            <feOffset/>
                            <feGaussianBlur stdDeviation="17.5"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix"
                                           values="0 0 0 0 1 0 0 0 0 0.937255 0 0 0 0 0.247059 0 0 0 0.21 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3971_693"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3971_693" result="shape"/>
                        </filter>
                        <filter id="filter1_i_3971_693" x="35" y="41.0557" width="132" height="132"
                                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                            <feColorMatrix in="SourceAlpha" type="matrix"
                                           values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                           result="hardAlpha"/>
                            <feOffset dy="3"/>
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.65 0"/>
                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3971_693"/>
                        </filter>
                        <linearGradient id="paint0_linear_3971_693" x1="94.4409" y1="143.385" x2="94.4409" y2="76.376"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FD8637"/>
                            <stop offset="1" stopColor="#FFD347"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_3971_693" x1="115.735" y1="190.11" x2="90.6061" y2="37.3209"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FFF441"/>
                            <stop offset="1" stopColor="#FF7D32"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_3971_693" x1="194.248" y1="104.028" x2="44.789" y2="125.422"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#FFFD64"/>
                            <stop offset="1" stopColor="#C42F00"/>
                        </linearGradient>
                    </defs>
                </svg>
                <div className={[animation ? cl.amountActive : cl.amount, !resultGame?.win ? cl.amount_red : cl.amount_gold].join(" ")}>{resultGame?.win ? '+' : "-"}{amount}</div>
            </div>
            <div className={cl.stars}>
                <Lottie
                    options={defaultOptionsStars}
                    height={300}
                    width={300}
                    isStopped={isStopped}
                    isPaused={!isPlaying}
                    speed={1}
                />
            </div>
        </div>
    );
};

export default Coin;