import React from 'react';

import cl from "./layout.module.scss"
import Navbar from "../navbar/navbar";

const Layout = ({children}) => {

    return (
        <div className={cl.block}>
            <div className={cl.body}>
                {children}
            </div>
            <Navbar/>
        </div>
    );
};

export default Layout;