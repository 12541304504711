import axios from "axios";
import {httpUrl} from "../config/api.config";

class FriendsService{
    async getAll(id){
        const {data} = await axios.get(httpUrl+`/friends?id=${id}`)
        return data
    }
}
//eslint-disable-next-line
export default new FriendsService();