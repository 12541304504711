import axios from "axios";
import {httpUrl} from "../config/api.config";

class UserService {
    async get(id, username) {
        const {data} = await axios.get(httpUrl+`/user?id=${id}&username=${username}`);
        return data;
    }

    async updateWallet(dataResponse){
        const {data} = await axios.patch(httpUrl+`/user/wallet`, dataResponse);
        return data;
    }

    async updateFaq(id){
        const {data} = await axios.patch(httpUrl+`/user/faq/?id=${id}`);
        return data;
    }
}

export default new UserService();