import axios from "axios";
import {$auth, httpUrl} from "../config/api.config";

class TranslateService {
    async translate(dataTranslate){
        const {data} = await axios.post(httpUrl+`/translate`, dataTranslate)
        return data
    }

    async withdrawal(dataWithdrawal){
        const {data} = await axios.post(httpUrl+`/translate/withdrawal`, dataWithdrawal)
        return data
    }

    async getRule(){
        const {data} = await axios.get(httpUrl+`/translate/rule`)
        return data
    }
    async get(id){
        const {data} = await axios.get(httpUrl+`/translate?id=${id}`)
        return data
    }
    async getAll(){
        const {data} = await $auth.get(httpUrl+`/translate/all`)
        return data
    }

    async updateStatus(dataUpdate){
        const {data} = await $auth.patch(httpUrl+`/translate/`, dataUpdate)
        return data
    }

    async updateRule(dataRule){
        const {data} = await $auth.patch(httpUrl+`/translate/rule`, dataRule)
        return data
    }
    async updateMinWithdrawal(dataRule){
        const {data} = await $auth.patch(httpUrl+`/translate/minwithdrawal`, dataRule)
        return data
    }

}
export default new TranslateService();