import axios from "axios";
import {httpUrl} from "../config/api.config";

class BonusService{
    async get(id){
        const {data} = await axios.get(httpUrl + `/bonus?id=${id}`);
        return data;
    }

    async check(id){
        const {data} = await axios.get(httpUrl + `/bonus/check?id=${id}`);
        return data;
    }


}
//eslint-disable-next-line
export default new BonusService();