import {useMutation, useQueryClient} from "@tanstack/react-query";
import TranslateService from "../services/translate.service";
import {toast} from "sonner";

export const useWithdrawalUpdateMinAmount = () =>{
    const queryClient = useQueryClient();

    const {mutate: withdrawalUpdateMinAmount} = useMutation({
        mutationKey: ["withdrawalUpdateMinAmount"],
        mutationFn: async (data) => {
            await TranslateService.updateMinWithdrawal({number: data})
        },
        onError: (e)=>{
            toast.error(e.response.data.message)
        },
        onSuccess: async ()=>{
            queryClient.invalidateQueries({queryKey: ["rule"]})
            toast.success("Мин. сумма вывода измененна")
        },
    })

    return {withdrawalUpdateMinAmount}

}

