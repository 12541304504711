import React from 'react';

import cl from "./bet.module.scss"
import useSound from "use-sound";
import clickSound from "../../../sounds/click.mp3";


const Bet = ({bet, setBet, amount}) => {


    const [play] = useSound(clickSound)

    const btns = [
        {
            title: "Мин",
            function: (bet)=>{
                play()
                return 100
            }
        },
        {
            title: "-500",
            function: (bet)=>{
                play()
                return Math.max(bet - 500, 100)
            }
        },
        {
            title: "-100",
            function: (bet)=>{
                play()
                return Math.max(bet - 100, 100)
            }
        },

        {
            title: "+100",
            function: (bet)=>{
                play()
                return Math.min((bet || 0) + 100, amount)
            }
        },
        {
            title: "+500",
            function: (bet)=>{
                play()
                return Math.min((bet || 0) + 500, amount)
            }
        },
        {
            title: "Макс",
            function: (bet)=>{
                play()
                return amount
            }
        }
    ]


    return (
        <div className={cl.block}>
            <div>
                <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M10.2701 13.2822L10.1686 13.0923H9.10888L16.4727 27.071H15.8207L7.31738 12.411L10.0677 12.2344L10.6985 13.2184L10.2701 13.2822Z"
                        fill="#0C4388"/>
                    <path d="M20.282 21.0793L20.2371 21.543L14.0459 10.3398H14.7617L20.282 21.0793Z"
                          fill="#0C4388"/>
                    <path
                        d="M19.8838 21.0902V21.215H20.1613L25.2132 10.3398H24.8546L19.909 21.1393L19.8838 21.0902Z"
                        fill="#1A90D8"/>
                    <path
                        d="M25.006 10.3398H29.4722L22.1295 27.0689H17.4111L9.8666 12.9388H8.80684L16.2504 27.0689H11.2544L3.0791 10.3398H14.5094L20.0605 21.1393L25.006 10.3398Z"
                        fill="url(#paint0_linear_3739_632)"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M16.502 29.7198C23.8041 29.7198 29.7237 23.8002 29.7237 16.498C29.7237 9.19588 23.8041 3.2763 16.502 3.2763C9.19978 3.2763 3.2802 9.19588 3.2802 16.498C3.2802 23.8002 9.19978 29.7198 16.502 29.7198ZM16.502 33C25.6157 33 33.0039 25.6118 33.0039 16.498C33.0039 7.38427 25.6157 -0.00390625 16.502 -0.00390625C7.38818 -0.00390625 0 7.38427 0 16.498C0 25.6118 7.38818 33 16.502 33Z"
                          fill="url(#paint1_linear_3739_632)"/>
                    <path
                        d="M29.7235 16.5001C29.7235 23.8023 23.8039 29.7219 16.5017 29.7219C12.8166 29.7219 9.48355 28.2142 7.0858 25.7821L4.71289 28.0473C7.70816 31.1049 11.8834 33.0021 16.5017 33.0021C25.6155 33.0021 33.0037 25.6139 33.0037 16.5001C33.0037 12.1573 31.3261 8.20627 28.5836 5.25977L26.2107 7.525C28.391 9.8825 29.7235 13.0357 29.7235 16.5001Z"
                        fill="url(#paint2_linear_3739_632)"/>
                    <path fillRule="evenodd" clipRule="evenodd"
                          d="M30.3806 16.4989C30.3806 24.1634 24.1673 30.3767 16.5028 30.3767C8.8383 30.3767 2.625 24.1634 2.625 16.4989C2.625 8.83439 8.8383 2.62109 16.5028 2.62109C24.1673 2.62109 30.3806 8.83439 30.3806 16.4989ZM29.7245 16.4989C29.7245 23.8011 23.805 29.7206 16.5028 29.7206C9.20062 29.7206 3.28104 23.8011 3.28104 16.4989C3.28104 9.19671 9.20062 3.27713 16.5028 3.27713C23.805 3.27713 29.7245 9.19671 29.7245 16.4989Z"
                          fill="url(#paint3_linear_3739_632)"/>
                    <defs>
                        <linearGradient id="paint0_linear_3739_632" x1="29.0684" y1="11.223" x2="3.96223"
                                        y2="10.3398" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#0C1F54"/>
                            <stop offset="0.445" stopColor="#1466BA"/>
                            <stop offset="1" stopColor="#35F4FF"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_3739_632" x1="6.88843" y1="2.59502" x2="26.9229"
                                        y2="30.3254" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4AF6FF"/>
                            <stop offset="0.495" stopColor="#102A6E"/>
                            <stop offset="1" stopColor="#0C1A3B"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_3739_632" x1="16.0475" y1="35.5001" x2="34.1391"
                                        y2="15.6675" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#23A8F9" stopOpacity="0"/>
                            <stop offset="0.455" stopColor="#23A8F9"/>
                            <stop offset="1" stopColor="#23A8F9" stopOpacity="0"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_3739_632" x1="25.1827" y1="27.374" x2="9.16018"
                                        y2="4.33689" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#4AF6FF"/>
                            <stop offset="0.495" stopColor="#102A6E"/>
                            <stop offset="1" stopColor="#0C1A3B"/>
                        </linearGradient>
                    </defs>
                </svg>
                <input type="number" className={cl.input} value={bet || ""} onChange={e => setBet(e.target.value)}
                       placeholder="Ваша ставка"/>
                <button className={cl.cross} onClick={e => setBet("")}>
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L13 13" stroke="#2C303E" strokeWidth="2" strokeLinecap="round"/>
                        <path d="M13 1L1 13" stroke="#2C303E" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                </button>
            </div>
            <div className={cl.row}>
                {btns.map(btn=>
                    <button
                        key={btn.title}
                        className={cl.fastBtn}
                        onClick={e=> setBet(btn.function(bet))}
                    >
                        {btn.title}
                    </button>
                )}
            </div>
        </div>
    );
};

export default Bet;