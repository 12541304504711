import {useMutation, useQueryClient} from "@tanstack/react-query";
import TranslateService from "../services/translate.service";
import {toast} from "sonner";

export const useRuleWithdrawalUpdate = () => {
    const queryClient = useQueryClient();

    const {mutate: withdrawalUpdateRule} = useMutation({
        mutationKey: ["withdrawalUpdateRule"],
        mutationFn: async (data) => {
            await TranslateService.updateRule({status: data})
        },
        onError: (e)=>{
            toast.error(e.response.data.message)
        },
        onSuccess: async ()=>{
            queryClient.invalidateQueries({queryKey: ["rule"]})
        },
    })

    return {withdrawalUpdateRule}
}

