import cl from "./adminLayout.module.scss"
import {NavLink} from "react-router-dom";

const AdminLayout = ({children}) => {
    return (
        <div className={cl.block}>
            <img className={cl.cover} src="/image/admin/cover.png" alt=""/>
            <div className={cl.title}>NVT ADMIN</div>
            <div className={cl.navbar}>
                <NavLink to={"/admin"} className={({ isActive }) => (isActive ? [cl.link, cl.linkActive].join(" ") : cl.link)} end={true}>Статистика</NavLink>
                <NavLink to={"/admin/task"} className={({ isActive }) => (isActive ? [cl.link, cl.linkActive].join(" ") : cl.link)} end={true}>Функции</NavLink>
            </div>
            <div className={cl.body}>
                {children}
            </div>
        </div>
    );
};

export default AdminLayout;