import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import GameService from "../services/game.service";
import {toast} from "sonner";



export const useGame = (id) =>{

    const {data: start} = useQuery({
        queryKey: ["startGame"],
        queryFn: () => GameService.start(id),

    });

    const queryClient = useQueryClient();

    const {mutateAsync} = useMutation({
        mutationFn: (dataResponse)=> GameService.play(dataResponse)
    })

    const play = async (data)=>{
        try{
            const game = await mutateAsync(data)
            queryClient.invalidateQueries({queryKey: ["user"]})
            setTimeout(function(){
                queryClient.invalidateQueries({queryKey: ["startGame"]})
            },2000);
            return game
        }catch (e) {
            toast.error(e.response.data.message)
        }
    }

    return {
        start,
        play
    }
}