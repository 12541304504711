import {useMutation, useQueryClient} from "@tanstack/react-query";
import UserService from "../services/user.service";

export const useFaq = () => {

    const queryClient = useQueryClient()

    const {mutate: updateFaq, isPending} = useMutation({
        mutationKey: ["faq"],
        mutationFn: async (id) => {
            await UserService.updateFaq(id)
        },
        onSuccess: ()=>{
            queryClient.invalidateQueries({queryKey: ["user"]})
        },
    })

    return {
        updateFaq,
        isPending
    }
}