import React, {useEffect, useState} from 'react';
import Layout from "../../components/layout/layout";
import MotionNumber from 'motion-number'
import cl from "./main.module.scss"
import {useTelegram} from "../../hooks/useTelegram.hook";
import {useGame} from "../../hooks/useGame.hook";
import {useUser} from "../../hooks/useUser";
import Coin from "../../components/main/coin/coin";
import SliderResult from "../../components/main/sliderResult/sliderResult";
import {toast} from "sonner";
import Bet from "../../components/main/bet/bet";
import Actions from "../../components/main/actions/actions";
import {Link, useNavigate} from "react-router-dom";
import Bonus from "../../components/main/bonus/bonus";
import Wallet from "../../components/main/wallet/wallet";
import {useFaq} from "../../hooks/useFaq.hook";

const MainPage = () => {

    const {id, username} = useTelegram()
    const {updateFaq} = useFaq()
    const {start, play} = useGame(id)
    const {user, userLoading} = useUser(id,username)
    const [bet, setBet] = useState("");
    const [resultGame, setResultGame] = useState();
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [animationPlay, setAnimationPlay] = useState(true);
    const [amount, setAmount] = useState(user?.amount || 0)
    const navigate = useNavigate();

    useEffect(()=>{
        if(user?.faq === false && userLoading === false){
            updateFaq(id)
            navigate("/faq")
        }
    }, [user, userLoading])

    useEffect(() => {
        setAnimationPlay(true)
        setTimeout(function(){
            setAnimationPlay(false)
            setBtnDisabled(false)
        },2000);
        return clearTimeout
    }, [resultGame]);

    useEffect(() => {
        setTimeout(()=>{
            setAmount(user?.amount)
        }, 2000)
        return clearTimeout
    }, [user]);

    const send = async (value, e) =>{
        e.preventDefault()

        if(bet < 100 || bet === ""){
            toast.error("Минимальная ставка 100 NVT")
            return
        }
        if(user.amount < bet){
            toast.error("Недостаточно  средств")
            return
        }

        const data = await play({
            assumption: value,
            id,
            bet
        })

        setResultGame(data)
        setBtnDisabled(true)
    }

    return (
        <Layout>
            <img className={cl.cover} src="/image/backgrounds/main.png" alt=""/>
            <div className={cl.row}>
                <Bonus active={user?.bonus}/>
                <Wallet wallet={user?.wallet} id={id}/>
            </div>
            <div className={cl.body}>
                <Coin amount={resultGame?.amountChange} resultGame={resultGame}/>
                <div className={cl.amount}>
                    <MotionNumber
                        value={amount || 0}
                        locales="en-US"
                    /> NVT
                </div>
                <Bet bet={bet || 0} setBet={setBet} amount={user?.amount}/>
                <SliderResult number={start} result={resultGame?.resultNumber} animationPlay={animationPlay}
                              prevNumber={resultGame?.game}/>
                <Actions start={start} send={send} disabled={btnDisabled}/>
            </div>
        </Layout>
    );
};

export default MainPage;