import "./main.scss"
import AppRouter from "./components/AppRouter";
import {BrowserRouter} from "react-router-dom";
import {useTelegram} from "./hooks/useTelegram.hook";
import {Providers} from "./components/providers";
import {TonConnectUIProvider} from "@tonconnect/ui-react";
import {Toaster} from "sonner";
import React, {useContext, useEffect} from "react";
import {Context} from "./index";
import {$auth} from "./config/api.config";


function App() {
    const {webApp} = useTelegram()
    webApp.expand()
    const {admin} = useContext(Context);
    useEffect(() => {
        const auth = async() =>{
            admin.setLoading(true)
            try {
                const {data} = await $auth.post("/admin/refresh")
                if(data){
                    admin.setIsAuth(true)
                }
            }catch (e) {
                console.log(e)
            }
            admin.setLoading(false)
        }
        auth()
    });
    return (
        <div className="App">
            <Toaster position="top-right" theme={"dark"} richColors={true}/>
            <TonConnectUIProvider manifestUrl="https://nvutti.tech/tonconnect-manifest.json">
                <div className="wrapper">
                    <div className="main_container">
                        <Providers>
                            <BrowserRouter>
                                <AppRouter/>
                            </BrowserRouter>
                        </Providers>
                    </div>
                </div>
            </TonConnectUIProvider>
        </div>
    );
}

export default App;
