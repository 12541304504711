import MainPage from "./pages/main/main";
import TaskPage from "./pages/task/task";
import FriendsPage from "./pages/friends/friends";
import FaqPage from "./pages/faq/faq";
import AirdropPage from "./pages/airdrop/airdrop";
import BonusPage from "./pages/bonus/bonus";
import LoginPage from "./pages/login/login";
import AdminPage from "./pages/admin/admin";
import AdminTaskPage from "./pages/adminTaskPage/adminTaskPage";
import WithdrawalPage from "./pages/withdrawal/withdrawal";

export const publicRoutes = [
    {
        path: '/',
        Component: MainPage
    },{
        path: '/friends',
        Component: FriendsPage
    },{
        path: '/tasks',
        Component: TaskPage
    },{
        path: '/faq',
        Component: FaqPage
    },{
        path: '/airdrop',
        Component: AirdropPage
    },{
        path: '/bonus',
        Component: BonusPage
    },{
        path: '/withdrawal',
        Component: WithdrawalPage
    },{
        path: '/login',
        Component: LoginPage
    }
]

export const privateRoutes = [
    {
        path: "/admin",
        Component:AdminPage
    },{
        path: "/admin/task",
        Component:AdminTaskPage
    }
]