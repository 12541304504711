import React from 'react';
import Layout from "../../components/layout/layout";
import cl from "./friends.module.scss"
import {useTelegram} from "../../hooks/useTelegram.hook";
import {useFriends} from "../../hooks/useFriends";
import {toast } from 'sonner'
import ContentLoader from "react-content-loader";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {botLink} from "../../config/settings.config";
import useSound from "use-sound";
import clickSound from "../../sounds/click.mp3";

const FriendsPage = () => {

    const {id} = useTelegram()
    const {friends, isLoading} = useFriends(id)
    const [play] = useSound(clickSound)
    return (
        <Layout>
                <img className={cl.cover} src="/image/backgrounds/main.png" alt=""/>
                <div className={cl.title}>Получай 1000 токенов за каждого приглашенного друга!</div>
                <div className={cl.body}>
                    {friends && friends.map((friend) => (
                        <CopyToClipboard key={friend.id} onCopy={() => {
                            play()
                            toast.success("Username скопирован!")
                        }}
                                         text={"@" + friend.username}>
                            <div className={cl.item}>
                                <div className={cl.item__name}>@{friend.username}</div>
                                <div className={cl.item__amount}>{friend.amount} NVT</div>
                            </div>
                        </CopyToClipboard>

                    ))}

                    {isLoading && <>
                        <ContentLoader
                            speed={2}
                            width={390}
                            height={57}
                            viewBox="0 0 390 60"
                            backgroundColor="#2b2d3a"
                            foregroundColor="#474952"
                        >
                            <rect x="12" y="20" rx="0" ry="0" width="1" height="0"/>
                            <rect x="78" y="88" rx="0" ry="0" width="0" height="1"/>
                            <rect x="0" y="0" rx="15" ry="15" width="390" height="60"/>
                        </ContentLoader>
                    </>}
                    <CopyToClipboard
                        text={botLink + `?start=${id}`}
                        onCopy={()=> {
                            play()
                            toast.success("Ссылка скопирована!")
                        }}
                    >
                        <button className={cl.addFriend}>
                            <div className={cl.addFriend__text}>
                                Скопировать реферальную ссылку
                            </div>
                        </button>
                    </CopyToClipboard>
                </div>
        </Layout>
    );
};

export default FriendsPage;