import React, {useState} from 'react';
import Layout from "../../components/layout/layout";
import cl from "./faq.module.scss"
import useImagePreloader from "../../hooks/useImagePreloader";
import {useNavigate} from "react-router-dom";
import useSound from "use-sound";
import clickSound from "../../sounds/click.mp3";
import {appUrl} from "../../config/api.config"

const pages = [
    {
        step: 1,
        title: "Играйте и зарабатывайте монеты",
        image: "/image/faq/game.svg"
    },
    {
        step: 2,
        title: "Выполняйте задания за дополнительные NVT",
        image: "/image/faq/task.svg"
    },
    {
        step: 3,
        title: "Приглашайте друзей в реферальную программу",
        image: "/image/faq/friends.svg"
    },
    {
        step: 4,
        title: `<div>Получайте ежедневный бонус:</div><div>от 1000 до 10 000 NVT</div>`,
        image: "/image/faq/bonus.svg"
    },
]


const FaqPage = () => {

    const [play] = useSound(clickSound)

    useImagePreloader(pages.map(item => item.image))
    const [step, setStep] = useState(1);

    const handlerStep = () => {
        play()
        if(step < pages.length) {
            setStep(prev => prev + 1)
        }else{
            window.location.replace(appUrl);
        }
    }

    return (
        <Layout>
            <img className={cl.cover} src={"/image/faq/cover.svg"} alt={"cover"}/>
            <div className={cl.imageWrapper}>
                <img className={cl.image} src={pages[step - 1].image} alt={pages[step - 1].title}/>
            </div>
            <div className={cl.container}>
                {
                    pages.map((page, i) =>
                        <div className={cl.step} key={page.title}>
                            <div className={cl.number}>
                                <div
                                    className={page.step <= step ? [cl.numberText, cl.numberTextActive].join(" ") : cl.numberText}>{page.step}</div>
                                <div className={cl.circleMask}>
                                    <div
                                        className={page.step <= step ? [cl.rectangle, cl.rectangleActive].join(" ") : cl.rectangle}></div>
                                </div>
                            </div>
                            {i < pages.length - 1 &&
                                <div
                                    className={page.step < step ? [cl.jumper, cl.jumperActive].join(" ") : cl.jumper}></div>
                            }
                        </div>
                    )
                }
            </div>
            <div className={cl.title} dangerouslySetInnerHTML={{__html: pages[step - 1].title}}></div>
            <div className={cl.row}>
                <button className={cl.next} onClick={handlerStep}>Продолжить</button>
            </div>
        </Layout>
    );
};

export default FaqPage;