import {useMutation, useQueryClient} from "@tanstack/react-query";
import TaskService from "../services/task.service";
import {toast} from "sonner";

export const useCreateTask = () => {
    const queryClient = useQueryClient();

    const {mutate: updateTask} = useMutation({
        mutationKey: ["taskCreate"],
        mutationFn: async (data) => {
            await TaskService.create(data)
        },
        onError: (e)=>{
            toast.error(e.response.data.message)
        },
        onSuccess: ()=>{
            queryClient.invalidateQueries({queryKey: ["task"]})
        },
    })
    return {updateTask}
}