import {useQuery} from "@tanstack/react-query";
import UserService from "../services/user.service";

export const useUser = (id, username) =>{

    const {data, isLoading} = useQuery({
        queryKey: ["user"],
        queryFn: () => UserService.get(id, username),
    });
    return {user: data, userLoading: isLoading};
}