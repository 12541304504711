import {useMutation, useQueryClient} from "@tanstack/react-query";
import TranslateService from "../services/translate.service";
import {toast} from "sonner";

export const useWithdrawalUpdateStatus = () => {
    const queryClient = useQueryClient();

    const {mutate: withdrawalUpdate} = useMutation({
        mutationKey: ["withdrawalUpdateStatus"],
        mutationFn: async (data) => {
            await TranslateService.updateStatus(data)
        },
        onError: (e)=>{
            toast.error(e.response.data.message)
        },
        onSuccess: async ()=>{
            queryClient.invalidateQueries({queryKey: ["withdrawals"]})
            toast.success("Статус изменен");
        },
    })

    return {withdrawalUpdate}
}