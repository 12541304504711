import React, {useContext, useEffect, useState} from 'react';
import cl from "./login.module.scss"
import {useLogin} from "../../hooks/useLogin.hook";
import {useNavigate} from "react-router-dom";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";

const LoginPage = observer(() => {

    const [data, setData] = useState({login: "", password:""})
    const {login} = useLogin()
    const {admin} = useContext(Context);
    const navigate = useNavigate();
    if(admin?.isAuth){
        navigate("/admin")
    }

    return (
        <div className={cl.block}>
            <div className={cl.header}>NVT ADMIN</div>
            <div className={cl.form}>
                <div className={cl.title}>Авторизация</div>
                <input className={cl.input} placeholder={"Логин"} value={data.login} onChange={e => {setData({...data, login: e.target.value})}}/>
                <input className={cl.input} placeholder={"Пароль"} type={"password"} value={data.password} onChange={e => {setData({...data, password: e.target.value})}}/>
                <button className={cl.btn} onClick={e=> {
                    login(data)
                }}>Войти</button>
            </div>
        </div>
    );
});

export default LoginPage;