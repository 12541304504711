import React, {useState} from 'react';
import cl from "./wallet.module.scss"
import useSound from "use-sound";
import clickSound from "../../../sounds/click.mp3"
import {toast} from "sonner";
import {useWallets} from "../../../hooks/useWallet.hook";
import {useTonConnectUI} from '@tonconnect/ui-react';
import * as TonConnectSDK from "@tonconnect/sdk";

const Wallet = ({wallet, id}) => {
    const [visible, setVisible] = useState(false);
    const [play] = useSound(clickSound)
    const {updateWallet} = useWallets()
    const [tonConnectUI] = useTonConnectUI();

    const disconnectWallet = async () => {
        play()
        try {
            await tonConnectUI.disconnect();
            updateWallet({
                id,
                wallet: null
            })
        }catch (e) {

        }
    }

    const handleConnect = async () => {
        try {
            const wallet = await tonConnectUI.connectWallet();
            let address = wallet.account.address
            if (wallet) {
                let wallet = TonConnectSDK.toUserFriendlyAddress(address)
                updateWallet({
                    id,
                    wallet
                })
            }
        } catch (error) {
            toast.error(error);
        }
    };

    return (
        <div className={cl.block}>
            <button className={cl.btn} onClick={()=> {
                setVisible(true);
                play()
            }}>Кошелек</button>
            <div className={[cl.wrapper, visible && cl.wrapperActive].join(" ")} onClick={()=>setVisible(false)}>
                <div className={cl.modal} onClick={e=>e.stopPropagation()}>
                    <div className={cl.column}>
                        {
                            wallet !== null
                                ? <>
                                    <div className={cl.wallet}>Кошелек: {wallet?.slice(0, 4)}...{wallet?.slice(-5)}</div>
                                    <button onClick={()=>disconnectWallet()} className={cl.disconnect}>Отвязать</button>
                                </>
                                : <button className={cl.walletAdd} onClick={handleConnect}>
                                    Подключить кошелек
                                </button>
                        }
                    </div>

                    <div className={cl.cross} onClick={()=> {
                        play()
                        setVisible(false)
                    }}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.297042 0.297025C0.693067 -0.0990082 1.3352 -0.0990082 1.73122 0.297025L9.09805 7.66355L16.2688 0.49311C16.6648 0.0970778 17.3069 0.0970778 17.703 0.49311C18.099 0.889143 18.099 1.53123 17.703 1.92727L10.5323 9.0977L17.5076 16.0727C17.9036 16.4688 17.9036 17.1109 17.5076 17.5069C17.1115 17.9029 16.4694 17.9029 16.0734 17.5069L9.09805 10.5319L1.92664 17.703C1.53058 18.099 0.88848 18.099 0.492424 17.703C0.0963679 17.3069 0.0963679 16.6648 0.492424 16.2688L7.66383 9.0977L0.297042 1.73118C-0.099014 1.33515 -0.099014 0.693057 0.297042 0.297025Z"
                                fill="#D9D9D9"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Wallet;