import {useMutation} from "@tanstack/react-query";
import {toast} from "sonner";
import AdminService from "../services/admin.service";

export const useLogin = () => {

    const {mutate: login} = useMutation({
        mutationKey: ["login"],
        mutationFn: async (data) => {
            await AdminService.login(data)
        },
        onError: (e)=>{
            toast.error(e.response.data.message)
        },
        onSuccess: ()=>{
            window.location = "/admin"
        },
    })

    return {
        login
    }

}