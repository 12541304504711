import React from 'react';

import cl from "./taskDeletePopup.module.scss"
import {useDeleteTask} from "../../../hooks/useDeleteTask.hook";

const TaskDeletePopup = ({visible, setVisible, id}) => {

    const {deleteTask} = useDeleteTask()

    const deleteTaskFun = () =>{
        deleteTask(id)
        setVisible(false)
    }

    return (
        <div className={visible ? [cl.wrapper, cl.wrapperActive].join(" ") : cl.wrapper} onClick={() => setVisible(false)}>
            <div className={cl.body} onClick={(e) => e.stopPropagation()}>
                <button className={cl.cross} onClick={()=>setVisible(false)}>
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.714 0.285992C12.3327 -0.0953253 11.7144 -0.0953358 11.3331 0.285992L6.5 5.11911L1.66688 0.285992C1.28556 -0.0953253 0.667315 -0.0953358 0.285988 0.285992C-0.0953292 0.667308 -0.0953292 1.28557 0.285988 1.66688L5.11911 6.5L0.285988 11.3331C-0.0953292 11.7144 -0.0953292 12.3327 0.285988 12.714C0.667305 13.0953 1.28556 13.0953 1.66688 12.714L6.5 7.88089L11.3331 12.714C11.7144 13.0953 12.3327 13.0953 12.714 12.714C13.0953 12.3327 13.0953 11.7144 12.714 11.3331L7.88089 6.5L12.714 1.66688C13.0953 1.28555 13.0953 0.667308 12.714 0.285992Z"
                            fill="white"/>
                    </svg>
                </button>
                <div className={cl.title}>Реально удалить? Или нет?)</div>
                <div className={cl.row}>
                    <button className={cl.delete} onClick={deleteTaskFun}>Удалить</button>
                    <button className={cl.close} onClick={()=>setVisible(false)}>Не удалять</button>
                </div>
            </div>
        </div>
    );
};

export default TaskDeletePopup;