import {useQuery} from "@tanstack/react-query";
import FriendsService from "../services/friends.service";


export const useFriends = (id) => {
    const {data, isLoading} = useQuery({
        queryKey: ["friends"],
        queryFn: () => FriendsService.getAll(id)
    });
    return {friends: data, isLoading};
}