import {useMutation, useQueryClient} from "@tanstack/react-query";

import {toast} from "sonner";
import UserService from "../services/user.service";

export const useWallets = () => {
    const queryClient = useQueryClient()
    const {mutate: updateWallet} = useMutation({
        mutationKey: ["translate"],
        mutationFn: async (data) => {
            await UserService.updateWallet(data)
        },
        onError: (e)=>{
            toast.error(e.response.data.message)
        },
        onSuccess: ()=>{
            toast.success("Кошелек обнавлен");
            queryClient.invalidateQueries({queryKey: ["user"]})
        },

    })
    return {updateWallet}
}