import React, {useState} from 'react';

import cl from "./popupEditWithdrawal.module.scss"
import ConfirmPopup from "../confirm/confirm";
import {useWithdrawalUpdateStatus} from "../../hooks/useWithdrawalUpdateStatus.hook";

const PopupEditWithdrawal = ({visible, setVisible, withdrawal}) => {
    const [confirm, setConfirm] = useState(false)
    const [confirmData, setConfirmData] = useState({title: ""})
    const {withdrawalUpdate} = useWithdrawalUpdateStatus()
    const updateStatus = (status) => {
        withdrawalUpdate({id: withdrawal.id, status})
        setVisible(false)
    }

    return (
        <>
            <ConfirmPopup visible={confirm} setVisible={setConfirm} func={confirmData.func} title={confirmData.title}/>
            <div className={visible ? [cl.wrapper, cl.wrapperActive].join(" ") : cl.wrapper} onClick={() => setVisible(false)}>
                <div className={cl.body} onClick={(e)=>e.stopPropagation()}>
                    <div className={cl.title}>{withdrawal?.title} {withdrawal?.date?.split("-")?.reverse()?.join(".")}</div>
                    <div className={cl.cross} onClick={() => setVisible(false)}></div>
                    <div className={cl.info}>
                        <div className={cl.row}>
                            <div className={cl.name}>Ник:</div>
                            <div className={cl.value}>@{withdrawal?.user?.username}</div>
                        </div>
                        <div className={cl.row}>
                            <div className={cl.name}>Кошелек:</div>
                            <div className={cl.value}>{withdrawal?.user?.wallet}</div>
                        </div>
                        <div className={cl.row}>
                            <div className={cl.name}>Количество:</div>
                            <div className={cl.value}>{withdrawal?.amount} NVT</div>
                        </div>
                    </div>
                    <div className={cl.actions}>
                        <button className={[cl.btn, cl.btnBlue, withdrawal?.status === "Новый" && cl.btnBlueActive].join(" ")} onClick={()=>updateStatus("Новый")}>Новый</button>
                        <button className={[cl.btn, cl.btnYellow, withdrawal?.status === "В обработке" && cl.btnYellowActive].join(" ")} onClick={()=>updateStatus("В обработке")}>В обр...</button>
                        <button className={[cl.btn, cl.btnGreen].join(" ")} onClick={()=> {
                            setConfirmData({func: () =>updateStatus("Выполнено"), title: "Реально готов?"})
                            setConfirm(true)
                        }}>Готов</button>
                        <button className={[cl.btn, cl.btnRed].join(" ")} onClick={()=>{
                            setConfirmData({func: () =>updateStatus("Отклонено"), title: "Реально отказ?"})
                            setConfirm(true)
                        }}>Отказ</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PopupEditWithdrawal;