import React, {useState} from 'react';
import AdminLayout from "../../components/adminLayout/adminLayout";
import {useStatistic} from "../../hooks/useStatistic.hook";
import cl from "./admin.module.scss"
import PopupEditWithdrawal from "../../components/popupEditWithdrawal/popupEditWithdrawal";
import {useQuery} from "@tanstack/react-query";
import TranslateService from "../../services/translate.service";
const AdminPage = () => {
    const {statistic, isPending} = useStatistic()
    const [editPopup, setEditPopup] = useState(false);
    const [withdrawal, setWithdrawal] = useState({});

    const {data: withdrawals} = useQuery({
        queryKey: ["withdrawals"],
        queryFn: () => TranslateService.getAll(),
    });

    return (
        <AdminLayout>
            <PopupEditWithdrawal visible={editPopup} setVisible={setEditPopup} withdrawal={withdrawal}/>
            {(statistic && !isPending) &&
                <div className={cl.block}>
                    <div className={cl.row}>
                        <div>
                            <div className={cl.circle}></div>
                            <div className={cl.name}>Кол-во игроков</div>
                        </div>
                        <div>
                            <div className={cl.progressBar}></div>
                        </div>
                        <div>
                            <div className={cl.value}>{statistic?.players}</div>
                        </div>
                    </div>
                    <div className={cl.row}>
                        <div>
                            <div className={cl.circle}></div>
                            <div className={cl.name}>Всего коинов</div>
                        </div>
                        <div>
                            <div className={cl.value}>{statistic?.tokens}NVT</div>
                        </div>
                    </div>
                </div>
            }
            <div className={cl.block}>
                <div className={cl.title}>Запросы на вывод</div>
                <div className={cl.items}>
                    {withdrawals?.length > 0 && withdrawals.map(item =>
                        <div className={cl.item} onClick={() => {
                            setWithdrawal(item)
                            setEditPopup(true)
                        }}>
                            <div className={cl.itemTitle}>{item.title} {item.date?.split("-")?.reverse()?.join(".")}</div>
                            <div className={cl.itemStatus}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0ZM10 4C9.73478 4 9.48043 4.10536 9.29289 4.29289C9.10536 4.48043 9 4.73478 9 5V10C9.00006 10.2652 9.10545 10.5195 9.293 10.707L12.293 13.707C12.4816 13.8892 12.7342 13.99 12.9964 13.9877C13.2586 13.9854 13.5094 13.8802 13.6948 13.6948C13.8802 13.5094 13.9854 13.2586 13.9877 12.9964C13.99 12.7342 13.8892 12.4816 13.707 12.293L11 9.586V5C11 4.73478 10.8946 4.48043 10.7071 4.29289C10.5196 4.10536 10.2652 4 10 4Z"
                                        fill="#5A6076"/>
                                </svg>
                                <div className={cl.itemCircle}
                                     style={{background: item?.status === "Новый" ? "#0d4de6" : "#ffaa0b"}}></div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </AdminLayout>
    );
};

export default AdminPage;