import {useMutation, useQueryClient} from "@tanstack/react-query";
import TaskService from "../services/task.service";
import {toast} from "sonner";

export const useDeleteTask = () => {

    const queryClient = useQueryClient();

    const {mutate: deleteTask} = useMutation({
        mutationKey: ["bonus"],
        mutationFn: async (id) => {
            await TaskService.delete(id)
        },
        onError: (e)=>{
            toast.error(e.response.data.message)
        },
        onSuccess: ()=>{
            queryClient.invalidateQueries({queryKey: ["task"]})
        },
    })
    return {deleteTask}
}